<script>
  import { onMount } from "svelte";
  import { goto } from "@sveltech/routify";
  import TransitionWrapper from "../../_components/other/TransitionWrapper.svelte";
  import Header from "../../_components/1_level/Header.svelte";
  import Metadata from "../../_components/other/Metadata.svelte";
  import Footer from "../../_components/1_level/Footer.svelte";
  import OurCulture from "../../_components/1_level/OurCulture.svelte";
  import isDataLoaded from "../../_tools/DataLoaded.js";
  import OurValuesV2 from "../../_components/1_level/OurValues-v2.svelte";
  import WeAreV2 from "../../_components/1_level/WeAre-v2.svelte";
  import OurEvents from "../../_components/1_level/OurEvents.svelte";
  import Diversity from "../../_components/1_level/Diversity.svelte";
  import OfficeTour from "../../_components/1_level/OfficeTour.svelte";
  import ReadBlog from "../../_components/1_level/ReadBlog.svelte";
  import HeaderImage from "../../_components/1_level/HeaderImage.svelte";

  const metadata = {};

  export let data = [];
  export let loading = true;

  async function fetchPage() {
    const response = await fetch(`https://api.breakwater.ee/api/about`);

    if (response.status === 200) {
      data = await response.json();
      if (!!data.seo) {
        metadata.title = data.seo.meta_title;
        metadata.description = data.seo.meta_description;
      }
      loading = false;
      return { data };
    }
    // If error redirect to 404 page
    console.error(response.status, "Request failed");
    $goto("/");
  }
  onMount(async () => {
    fetchPage();
  });
</script>

<Metadata {metadata} />

<TransitionWrapper>
  {#if isDataLoaded(data.header)}
    <Header isHomepage={false} headerData={data.header} showBorder={false} />
    <HeaderImage headerData={data.header} />
  {/if}
  {#if isDataLoaded(data.we_are)}
    <WeAreV2 weAreData={data.we_are} />
  {/if}
  {#if isDataLoaded(data.our_values)}
    <OurValuesV2 ourValuesData={data.our_values} />
  {/if}
  {#if isDataLoaded(data.our_events)}
    <OurEvents ourEventsData={data.our_events} />
  {/if}
  {#if isDataLoaded(data.diversity)}
    <Diversity diversityData={data.diversity} />
  {/if}
  {#if isDataLoaded(data.office_tour)}
    <OfficeTour officeTourData={data.office_tour} />
  {/if}
  {#if isDataLoaded(data.our_culture)}
    <OurCulture reverseOrder={true} ourCultureData={data.our_culture} />
  {/if}
  {#if isDataLoaded(data.read_blog)}
    <ReadBlog readBlogData={data.read_blog} />
  {/if}
  {#if isDataLoaded(data.footer)}
    <Footer footerData={data.footer} />
  {/if}
</TransitionWrapper>

<style lang="scss">
  @use "static/breakpoints";
  :global(.our-culture .our-culture--content div:nth-child(2)) {
    background: var(--white-variant);
    padding: 1.5em;
  }
  @media only screen and (max-width: breakpoints.$screen-sm) {
    :global(.our-culture) {
      margin-top: 1em !important;
    }
  }
</style>
