<script>
  export let weAreData;
</script>

<div class="container-fluid we-are-v2">
  <div class="container">
    <h2>{weAreData.title}</h2>
    <div class="wrapper row">
      <div class="col-md-12 col-lg-6 col-xl-7 wrapper-text">
        {@html weAreData.text}
      </div>
      <div class="col-md-12 col-lg-6 col-xl-5">
        <img
          src={weAreData.image.url}
          alt={weAreData.image.meta.alt}
          loading="lazy"
          width="100%"
          height="100%"
        />
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  @use "static/breakpoints";
  .we-are-v2 {
    background: var(--white-variant);
    padding: 120px 0;

    h2 {
      font-size: 4.5em;
      text-transform: uppercase;
      font-weight: 800;
      line-height: 1.2em;
      color: var(--primary-variant);
      margin: 0 0 0.3em -15px;
    }
    .wrapper {
      background: var(--white);
      padding: 1em;
      img {
        margin-top: -7em;
        height: calc(100% + 7em);
        padding: 0 0 1em;
        object-fit: cover;
      }
      &-text {
        font-size: 0.9em;
        :global(hr) {
          width: 85%;
          border: 1px solid var(--primary);
        }
      }
    }
  }

  @media only screen and (max-width: breakpoints.$screen-lg) {
    .we-are-v2 {
      padding: 90px 0;
      h2 {
        margin: 0 0 0.3em 0;
      }
      .wrapper {
        margin: 0;
        img {
          margin-top: 1em;
          height: auto;
          width: 100%;
          max-height: 30em;
        }
      }
    }
  }
  @media only screen and (max-width: breakpoints.$screen-md) {
    .we-are-v2 {
      h2 {
        font-size: 4em;
      }
    }
  }
  @media only screen and (max-width: breakpoints.$screen-sm) {
    .we-are-v2 {
      h2 {
        font-size: 3.5em;
      }
      .wrapper {
        &-text {
          font-size: 0.8em;
        }
      }
    }
  }
  @media only screen and (max-width: breakpoints.$screen-xs) {
    .we-are-v2 {
      h2 {
        font-size: 3em;
      }
      .wrapper {
        &-text {
          font-size: 0.7em;
        }
      }
    }
  }
</style>
