<script>
  export let officeTourData;
</script>

<div class="container-fluid office-tour">
  <div class="office-tour--headline">
    <h2 class="mt-0">{@html officeTourData.title}</h2>
    <p class="mb-0">{@html officeTourData.sub_title}</p>
  </div>
  <iframe
    src={officeTourData.video}
    title={officeTourData.title}
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    referrerpolicy="strict-origin-when-cross-origin"
    allowfullscreen
  ></iframe>
</div>

<style lang="scss">
  @use "static/breakpoints";
  .office-tour {
    padding: 0;
    &--headline {
      background-color: var(--primary);
      color: var(--white);
      padding: 2em 15px;
      text-align: center;
      h2 {
        font-size: 2.2em;
        text-transform: uppercase;
        font-weight: 800;
        letter-spacing: 1px;
        font-family: var(--font-family-maven);
        line-height: 1.2em;
      }
      p {
        font-size: 1em;
      }
    }
    iframe {
      width: 100%;
      aspect-ratio: auto 16/9;
    }
  }

  @media only screen and (max-width: breakpoints.$screen-md) {
    .office-tour {
      &--headline {
        h2 {
          font-size: 2em;
        }
        p {
          font-size: 0.9em;
        }
      }
    }
  }
  @media only screen and (max-width: breakpoints.$screen-sm) {
    .office-tour {
      min-height: 580px;
      &--headline {
        h2 {
          font-size: 1.8em;
        }
        p {
          font-size: 0.8em;
        }
      }
    }
  }
</style>
