<script>
  export let diversityData;
</script>

<div class="container-fluid diversity">
  <div class="container">
    <div class="row diversity-row">
      <div class="title">{diversityData.title}</div>
      <div class="block row">
        <div class="col-md-12 col-lg-5 col-xl-4 block-text">
          {@html diversityData.text}
        </div>
        <div class="col-md-12 col-lg-7 col-xl-8 p-0">
          <img
            src={diversityData.image.url}
            alt={diversityData.image.meta.alt}
            loading="lazy"
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  @use "static/breakpoints";
  .diversity {
    padding: 120px 0;
    background-color: var(--white-variant);
    &-row {
      position: relative;
      overflow: hidden;
      flex-direction: row-reverse;
      flex-wrap: nowrap;
    }
    .title {
      writing-mode: vertical-rl;
      text-orientation: mixed;
      flex-basis: fit-content;
      color: var(--white);
      font-size: 6.5em;
      font-weight: 900;
      margin-left: 0.1em;
      text-transform: uppercase;
    }
    .block {
      display: flex;
      row-gap: 1.5em;
      background-color: var(--white);
      &-text {
        font-size: 0.9em;
        padding: 0.5em 1.5em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        :global(p) {
          padding: 0 1em;
        }
        :global(hr) {
          width: 80%;
          border: 1px solid var(--primary);
        }
      }
      img {
        object-fit: cover;
      }
    }
  }

  @media only screen and (max-width: breakpoints.$screen-xl) {
    .diversity {
      &-row {
        margin: 0 15px;
      }
    }
  }

  @media only screen and (max-width: breakpoints.$screen-lg) {
    .diversity {
      padding: 90px 0;
    }
  }

  @media only screen and (max-width: breakpoints.$screen-md) {
    .diversity {
      &-row {
        flex-direction: row;
        flex-wrap: wrap;
        overflow: unset;
      }
      .title {
        font-size: 4em;
        writing-mode: unset;
        text-orientation: unset;
        margin-left: unset;
        margin-bottom: 0.1em;
      }
    }
  }

  @media only screen and (max-width: breakpoints.$screen-sm) {
    .diversity {
      .title {
        font-size: 3em;
      }
    }
  }

  @media only screen and (max-width: breakpoints.$screen-xs) {
    .diversity {
      .title {
        font-size: 2.5em;
      }
    }
  }
</style>
