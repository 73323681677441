<script>
  export let ourEventsData;
</script>

<div class="container-fluid our-events">
  <div class="container">
    <h2>{ourEventsData.title}</h2>
    <div class="row mt-5">
      <div class="col-12">
        <div class="our-events--text">
          {@html ourEventsData.text}
        </div>
      </div>
      <div class="col-12 col-md-6">
        <iframe
          src={ourEventsData.video1}
          title={ourEventsData.videoTitle1}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        <span>{ourEventsData.videoTitle1}</span>
      </div>
      <div class="col-12 col-md-6">
        <iframe
          src={ourEventsData.video2}
          title={ourEventsData.videoTitle2}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        <span>{ourEventsData.videoTitle2}</span>
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  @use "static/breakpoints";
  .our-events {
    padding: 120px 0;
    h2 {
      font-size: 4.5em;
      text-transform: uppercase;
      font-weight: 800;
      line-height: 1.2em;
      color: var(--primary-variant);
      margin: 0 0 0.3em -15px;
    }

    &--text {
      font-size: 0.9em;
      width: 70%;
      text-align: center;
      margin: 0 auto;
      padding: 1em 0.5em 1.5em;
    }
    .row {
      position: relative;
      &::before {
        content: "";
        background-color: var(--white-variant);
        width: 70%;
        height: 80%;
        position: absolute;
        top: 0px;
        z-index: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
      iframe {
        width: 100%;
        aspect-ratio: auto 16 / 9;
      }
      span {
        font-size: 1em;
        font-weight: 800;
        display: block;
        margin-top: 0.2em;
      }
    }
  }

  @media only screen and (max-width: breakpoints.$screen-lg) {
    .our-events {
      padding: 90px 0;
      h2 {
        margin: 0 0 0.3em 0;
      }
      &--text {
        width: 75%;
      }
      .row {
        &::before {
          width: 75%;
        }
      }
    }
  }

  @media only screen and (max-width: breakpoints.$screen-md) {
    .our-events {
      h2 {
        font-size: 4em;
      }
      &--text {
        width: 100%;
        background-color: var(--white-variant);
        padding: 1em;
      }
      .row {
        &::before {
          display: none;
        }
        span {
          margin-bottom: 1.5em;
        }
      }
    }
  }
  @media only screen and (max-width: breakpoints.$screen-sm) {
    .our-events {
      h2 {
        font-size: 3.5em;
      }
      &--text {
        font-size: 0.8em;
      }
      .row {
        span {
          font-size: 0.9em;
        }
      }
    }
  }
  @media only screen and (max-width: breakpoints.$screen-xs) {
    .our-events {
      h2 {
        font-size: 3em;
      }
      &--text {
        font-size: 0.7em;
      }
      .row {
        span {
          font-size: 0.8em;
        }
      }
    }
  }
</style>
