<script>
  import SectionHeading from "../2_level/SectionHeading.svelte";

  export let ourValuesData = {};
</script>

<section class="our_values_v2 container-fluid">
  <div class="container p-0">
    <div class="container-wrapper">
      <SectionHeading title={ourValuesData.title} white={true} />
      <div class="row mx-0">
        <iframe
          src={ourValuesData.video}
          title={ourValuesData.title}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        <div class="video-text">{@html ourValuesData.text}</div>
      </div>
    </div>
  </div>
</section>

<style lang="scss">
  @use "static/breakpoints";
  .our_values_v2 {
    padding-top: 65px;
    padding-bottom: 120px;
    background-color: var(--primary);
    overflow-x: clip;
    position: relative;
    .container {
      display: flex;
      flex-direction: column;
      &::before {
        content: "";
        background-color: var(--primary);
        width: 390px;
        height: 100%;
        position: absolute;
        transform: skew(-28deg, 0deg);
        left: 275px;
        top: 0px;
        background: linear-gradient(
          0deg,
          var(--primary-dark) 0%,
          var(--primary) 100%
        );
        z-index: 0;
      }
      &-wrapper {
        z-index: 1;
        .row {
          margin-top: 20px;
          background-color: var(--white);
          iframe {
            width: 65%;
            aspect-ratio: auto 16/9;
          }
          .video-text {
            width: 35%;
            font-size: 0.9em;
            padding: 0.5em 1.5em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            :global(p) {
              margin: 0.5em;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: breakpoints.$screen-xl) {
    .our_values_v2 {
      padding: 90px 0;
      .container {
        &-wrapper {
          padding: 0 15px;
          .row {
            .video-text {
              font-size: 0.8em;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: breakpoints.$screen-lg) {
    .our_values_v2 {
      padding: 90px 0;
      .container {
        &-wrapper {
          .row {
            iframe {
              width: 100%;
            }
            .video-text {
              width: 100%;
              padding: 1.5em;
            }
          }
        }
      }
    }
  }
</style>
