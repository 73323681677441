<script>
  export let headerData;
</script>

<div class="container-fluid header-image">
  <img
    src={headerData.header_image.url}
    alt={headerData.header_image.meta.alt}
    loading="lazy"
    width="100%"
    height="100%"
  />
  <div class="container">
    <h1 class="mb-0">{@html headerData.header_title}</h1>
    <p class="mt-1">{headerData.header_sub_title}</p>
  </div>
</div>

<style lang="scss">
  @use "static/breakpoints";
  .header-image {
    padding: 120px 0 140px;
    position: relative;
    background: #0e76bd66;
    min-height: 1430px;
    display: flex;
    align-items: end;
    img {
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
    }
    .container {
      color: var(--white);
      h1 {
        font-size: 2.2em;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1px;
        font-family: var(--font-family-maven);
        line-height: 1.2em;
      }
      p {
        font-size: 0.9em;
      }
    }
  }

  @media only screen and (max-width: breakpoints.$screen-lg) {
    .header-image {
      padding: 90px 0 110px;
      min-height: 680px;
    }
  }

  @media only screen and (max-width: breakpoints.$screen-md) {
    .header-image {
      min-height: 640px;
      .container {
        h1 {
          font-size: 1.8em;
        }
        p {
          font-size: 0.8em;
        }
      }
    }
  }
  @media only screen and (max-width: breakpoints.$screen-sm) {
    .header-image {
      min-height: 580px;
      .container {
        h1 {
          font-size: 1.6em;
        }
        p {
          font-size: 0.7em;
        }
      }
    }
  }
  @media only screen and (max-width: breakpoints.$screen-xs) {
    .header-image {
      min-height: 520px;
      .container {
        h1 {
          font-size: 1.4em;
        }
        p {
          font-size: 0.6em;
        }
      }
    }
  }
</style>
