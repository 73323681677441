<script>
  import { onMount } from "svelte";
  import Swiper, { Autoplay } from "swiper";
  import BlogPostPreview from "../2_level/BlogPostPreview.svelte";

  export let readBlogData;

  let swiperContainer;
  let mySwiper;

  Swiper.use([Autoplay]);
  onMount(() => {
    mySwiper = new Swiper(swiperContainer, {
      speed: 400,
      spaceBetween: 20,
      slidesPerView: 2,
      loop: true,
      autoHeight: true,
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        },
      },
    });
  });

  const slidePrev = () => mySwiper.slidePrev();
  const slideNext = () => mySwiper.slideNext();
</script>

<div class="container-fluid read-blog">
  <div class="container">
    <h2>{readBlogData.title}</h2>
    <!-- start -->
    <div class="blog-slider">
      <div class="container blog-slider-wrapper">
        <div bind:this={swiperContainer} class="swiper-container py-2">
          <div class="swiper-wrapper">
            {#each readBlogData.items as post}
              <div class="swiper-slide">
                <BlogPostPreview postPreviewData={post} />
              </div>
            {/each}
          </div>
        </div>
        <div class="swiper-button-prev" on:click={() => slidePrev()} />
        <div class="swiper-button-next" on:click={() => slideNext()} />
      </div>
    </div>
  </div>
  <!-- emd -->
</div>

<style lang="scss">
  @use "static/breakpoints";
  .read-blog {
    padding: 120px 0;
    background: #efeff0;
    margin-top: 6em;
    h2 {
      font-size: 4.5em;
      text-transform: uppercase;
      font-weight: 800;
      line-height: 1.2em;
      color: var(--primary-variant);
      margin: 0 0 0.8em 0.1em;
    }
    .blog-slider {
      &-wrapper {
        position: relative;
      }
    }
  }

  @media only screen and (max-width: breakpoints.$screen-xl-with-swiper-buttons) {
    .blog-slider-wrapper {
      padding: 0 1.8em;
    }
  }
  @media only screen and (max-width: breakpoints.$screen-lg) {
    .read-blog {
      padding: 90px 0;
    }
  }

  @media only screen and (max-width: breakpoints.$screen-md) {
    .read-blog {
      h2 {
        font-size: 4em;
      }
    }
  }
  @media only screen and (max-width: breakpoints.$screen-sm) {
    .read-blog {
      h2 {
        font-size: 3.5em;
      }
    }
  }
  @media only screen and (max-width: breakpoints.$screen-xs) {
    .read-blog {
      h2 {
        font-size: 3em;
      }
    }
  }
</style>
